import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import { BLUE_TRANSPARENT } from '../helpers/constants';

export default function GoogleMaps() {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			<iframe
				src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7485.843054761989!2d-40.271335!3d-20.262089!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xb8184c4fb4d479%3A0x2c99c8136e6d04ff!2sR.%20Gelu%20Vervloet%20dos%20Santos%2C%20500%20-%20Jardim%20Camburi%2C%20Vit%C3%B3ria%20-%20ES%2C%2029090-100%2C%20Brasil!5e0!3m2!1spt-BR!2sus!4v1629948383322!5m2!1spt-BR!2sus"
				className={styles.googleMaps}
				loading={'lazy'}
			></iframe>
		</div>
	);
}

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'start',
			alignItems: 'center',
			width: '100%',
			backgroundColor: BLUE_TRANSPARENT,
		},
		googleMaps: {
			[theme.breakpoints.down('sm')]: {
				width: '100%',
				height: 300,
			},
			[theme.breakpoints.up('md')]: {
				width: '60%',
				height: 300,
			},
		},
	})
);
