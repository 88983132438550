import { makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core";
import { BLUE_DARK, WHITE } from "../helpers/constants";
import { RiHeartPulseLine } from "react-icons/ri";
import { BiBuildingHouse } from "react-icons/bi";
import { GiReceiveMoney } from "react-icons/gi";
import { FaUserMd } from "react-icons/fa";
import ButtonModal from "./ButtonModal";

export default function InfoButtons() {
	const styles = useStyles();
	const iconSize = 70;
	return (
		<div className={styles.container}>
			<ButtonModal
				label={"Saiba Mais"}
				tittle={"Condomínio"}
				modalText={
					"Um seguro sob medida para condomínios verticais residenciais, comerciais, mistos, de escritórios e/ou consultórios, flats e apart-hotéis, bem como condomínios residenciais horizontais. É um seguro completo, oferece coberturas que protegem o condomínio, o síndico e os colaboradores, além de facilidades e benefícios na contratação, serviços emergenciais gratuitos, preços e condições de pagamentos vantajosos."
				}
			>
				<BiBuildingHouse size={iconSize} className={styles.iconStyle} />
			</ButtonModal>
			<ButtonModal
				label={"Saiba Mais"}
				tittle={"Fiança Locatícia"}
				modalText={
					"Dê adeus ao fiador e a caução e fique tranquilo para cuidar da sua nova casa. É um seguro que pode ser contratado para aluguéis residenciais, comerciais e não residenciais (consultórios, escritórios, etc). O seguro fiança locatícia dispensa fiador e evita que você desfalque suas economias para reunir dinheiro equivalente a vários aluguéis, supondo que seja necessário fazer depósito-caução."
				}
			>
				<BiBuildingHouse size={iconSize} className={styles.iconStyle} />
			</ButtonModal>
			<ButtonModal
				label={"Saiba Mais"}
				tittle={"Responsabilidade Civil"}
				modalText={
					"A questão da responsabilidade civil está presente em toda a atividade humana. Atos involuntários podem causar prejuízos a outras pessoas e afetar o seu patrimônio, já que os desdobramentos de um acidente são difíceis de prever. O seguro de Responsabilidade Civil Geral representa proteção para situações que fogem do nosso controle."
				}
			>
				<FaUserMd size={iconSize} className={styles.iconStyle} />
			</ButtonModal>{" "}
			<ButtonModal
				label={"Saiba Mais"}
				tittle={"Incapacidade temporária"}
				modalText={
					"A proteção que os profissionais liberais e autônomos precisam para trabalhar com tranquilidade. Sabemos que seu trabalho é a conquista diária da sua independência. Mas se, por conta de um acidente ou doença, você precisar se afastar, este seguro garante uma indenização enquanto se recupera, com opções de franquia de 7 ou 10 dias para acidente e doença."
				}
			>
				<FaUserMd size={iconSize} className={styles.iconStyle} />
			</ButtonModal>{" "}
			<ButtonModal
				label={"Saiba Mais"}
				tittle={"Renda por Invalidez"}
				modalText={
					"Diferentemente do seguro de Incapacidade Temporária, que garante uma renda temporária (até 1 ano), o “Renda por Invalidez” traz o benefício de uma renda complementar vitalícia em caso de invalidez total e permanente por acidente ou doença."
				}
			>
				<FaUserMd size={iconSize} className={styles.iconStyle} />
			</ButtonModal>
			<ButtonModal
				label={"Saiba Mais"}
				tittle={"Empresarial"}
				modalText={
					"O  Seguro Empresarial foi desenvolvido para garantir o patrimônio das empresas contra os riscos a que estão expostos, proporcionando mais segurança e tranquilidade para sua empresa. É destinado a pessoa física ou jurídica, proprietário ou locatário de estabelecimento comercial, industrial ou de prestação de serviço."
				}
			>
				<GiReceiveMoney size={iconSize} className={styles.iconStyle} />
			</ButtonModal>
			<ButtonModal
				label={"Saiba Mais"}
				tittle={"Previdência"}
				modalText={
					"Um plano de previdência privada é a melhor forma de acumular recursos para realização dos seus projetos e planejar o seu futuro. Você pode diversificar seus investimentos a longo ou curto prazo. Investir em previdência privada pode custar menos do que você imagina. Descubra as diversas opções e a flexibilidade nas contribuições junto a Souza Pilares Corretora de Seguros."
				}
			>
				<GiReceiveMoney size={iconSize} className={styles.iconStyle} />
			</ButtonModal>
			<ButtonModal
				label={"Saiba Mais"}
				tittle={"Doenças Graves"}
				modalText={
					"Indeniza o segurado pelo valor contratado caso seja diagnosticado com uma das doenças cobertas pelo seguro; estas são:  Câncer, leucemias e doenças malignas do sistema linfático como a doença de Hodgkin; Infarto agudo do miocárdio; Acidente vascular cerebral (AVC);  Cirurgia de revascularização do miocárdio com implante de ponte(s) vascular (es) nas artérias coronarianas (bypass); Doença de Alzheimer."
				}
			>
				<RiHeartPulseLine size={iconSize} className={styles.iconStyle} />
			</ButtonModal>
		</div>
	);
}

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: "flex",
			flexWrap: "wrap",
			justifyContent: "center",
			alignItems: "start",
			backgroundColor: BLUE_DARK,
		},
		iconStyle: {
			color: WHITE,
		},
	})
);
