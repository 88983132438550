import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core";
import { BLUE_DARK, WHITE } from "../helpers/constants";
import { RiHeartPulseLine, RiHomeHeartLine } from "react-icons/ri";
import { GiMoneyStack, GiPayMoney, GiCarWheel } from "react-icons/gi";
import { FaUserInjured, FaUserTie } from "react-icons/fa";
import { IoAirplaneOutline } from "react-icons/io5";
import { MdPhonelinkLock } from "react-icons/md";
import ButtonRedirect from "./ButtonRedirect";

export default function ButtonIcons() {
	const styles = useStyles();
	const iconSize = 50 + 2 * ((window.innerWidth - 320) / 680);

	const buttonsInfo = [
		{
			tittle: "Vida",
			icon: <RiHeartPulseLine size={iconSize} className={styles.iconStyle} />,
			data: [
				{
					label: "Porto Seguro",
					link: "https://wwws.portoseguro.com.br/vendaonline/vidamaissimples/home.ns?cod=dc18acc8f6de4c0d98b27be8093835cf&utm_source=V5944J&utm_medium=geradorLinks&utm_campaign=GeradordeLinks_CI36YJ&utm_content=SOUZA_PILARES_ADM_COR_SEGS_VIDA_LTDA",
				},
				{
					label: "Azos",
					link: "https://www.azos.com.br/h/souza-pilares-hwiabt58",
				},
				{
					label: "SulAmérica",
					link: "https://contratafacil-segurovida.paas.sulamerica.com.br/?corretor_id=6dcf0cc5-82bc-4faa-baf0-df7c0dbbe7a2&utm_source=vida",
				},
				{
					label: "Unimed",
					link: "https://www.corretoraunimed.com.br/vida?areaAcao=souzapilares_corretoradeseguros",
				},
			],
		},

		{
			tittle: "Equipamentos Portáteis",
			icon: <MdPhonelinkLock size={iconSize} className={styles.iconStyle} />,
			data: [
				{
					label: "Porto Seguro",
					link: "https://wwws.portoseguro.com.br/vendaonline/equipamentosportateis/home.ns?cod=1ea568fa1e4e474f97ac98acf979838c&utm_source=V5944J&utm_medium=geradorLinks&utm_campaign=GeradordeLinks_CI36YJ&utm_content=SOUZA_PILARES_ADM_COR_SEGS_VIDA_LTDA",
				},
			],
		},
		{
			tittle: "Veículo",
			icon: <GiCarWheel size={iconSize} className={styles.iconStyle} />,
			data: [
				{
					label: "Porto Seguro Carro",
					link: "https://wwws.portoseguro.com.br/vendaonline/home.ns?cod=df29d9181d5d46bcb3eadcc3fbec66ce&utm_source=V5944J&utm_medium=geradorLinks&utm_campaign=GeradordeLinks_CI36YJ&utm_content=SOUZA_PILARES_ADM_COR_SEGS_VIDA_LTDA",
				},
				{
					label: "Porto Seguro Moto",
					link: "https://wwws.portoseguro.com.br/vendaonline/moto/home.ns?cod=f5e08417c02140fba00539cc194ed27b&utm_source=V5944J&utm_medium=geradorLinks&utm_campaign=GeradordeLinks_CI36YJ&utm_content=SOUZA_PILARES_ADM_COR_SEGS_VIDA_LTDA#vo-lgpd-frm-step-identify",
				},
			],
		},
		{
			tittle: "Residencial",
			icon: <RiHomeHeartLine size={iconSize} className={styles.iconStyle} />,
			data: [
				{
					label: "Porto Seguro",
					link: "https://wwws.portoseguro.com.br/vendaonline/residencia/home.ns?cod=0bec823a0d69474e961937b2fdb80a51&utm_source=V5944J&utm_medium=geradorLinks&utm_campaign=GeradordeLinks_CI36YJ&utm_content=SOUZA_PILARES_ADM_COR_SEGS_VIDA_LTDA#vo-lgpd-frm-step-identify",
				},
				{
					label: "Unimed",
					link: "https://www.corretoraunimed.com.br/residencial?areaAcao=souzapilares_corretoradeseguros",
				},
			],
		},
		{
			tittle: "Seguro Viagem",
			icon: <IoAirplaneOutline size={iconSize} className={styles.iconStyle} />,
			data: [
				{
					label: "Porto Seguro",
					link: "https://wwws.portoseguro.com.br/vendaonline/viagem/home.ns?cod=3495e648ccc44279b2eeb494e37aa8ee&utm_source=V5944J&utm_medium=geradorLinks&utm_campaign=GeradordeLinks_CI36YJ&utm_content=SOUZA_PILARES_ADM_COR_SEGS_VIDA_LTDA",
				},
				{
					label: "SulAmérica",
					link: "https://portal.sulamericaseguros.com.br/seguroviagem.htm?ref=eyJkYWRvc1Byb2R1Y2FvIjp7IkFBIjoiMTY4NjgiLCJBViI6IjAiLCJFQSI6IjUzOTE0IiwiRVYiOiIyNTQ0MTcyIiwidW9wRW1pc3NhbyI6IjEzMiIsInVvcE5lZ29jaW8iOiI0ODcyIn0sImNvcnJldG9yTm9tZSI6IlNPVVpBIFBJTEFSRVMgQURNIEUgQ09SUiBERSBTRUdTIExUREEiLCJpZENvcnJldG9yIjoiOTc2MzMiLCJwZXJjZW50dWFsQ29ycmV0YWdlbSI6W3sicGVyY2VudHVhbENvcnJldGFnZW0iOiIyNS4wMCJ9LHsicGVyY2VudHVhbEFnZW5jaWFtZW50byI6IjAuMDAifSx7InBlcmNlbnR1YWxQcmVzdGFjYW9TZXJ2aWNvIjoiMC4wMCJ9LHsiaW5kZXhPcGNhbyI6IjQifV0sIm5vbWVQcm9tb3RvciI6IiIsImltZ0NvcnJldG9yIjoiaHR0cHM6Ly9jb3JyZXRvcjIuc3VsYW1lcmljYXNlZ3Vyb3MuY29tLmJyL2RhdGEvZmlsZXMvQjAvQzIvOTYvNEMvOTdDRkM2MTA0RDc3M0ZDNjlCNDYxNkE4L0xPR08lMjBub3ZhLi5wbmcifQ==",
				},
				{
					label: "Unimed",
					link: "https://www.corretoraunimed.com.br/viagem?areaAcao=souzapilares_corretoradeseguros",
				},
			],
		},
		{
			tittle: "Acidentes Pessoais",
			icon: <FaUserInjured size={iconSize} className={styles.iconStyle} />,
			data: [
				{
					label: "Porto Seguro",
					link: "https://www.portoseguro.com.br/seguro-de-vida/acidentes-pessoais-individual-prazo-curto",
				},
				{
					label: "Seguro Estariários PASI",
					link: "https://seguros.portalpasi.com.br/login?Q=LK4CqQiH9Ndx2NwJhLS0/9G3P6XKfyibuSwTx93AQFYYlVdos3U/ZntekPSTR9s91QyP+it9VJP3PiIYkaCbjkErKkUoEUu+t+BBVCN/cmFezScPOgOKVNRp/+3jJCTe",
				},
				{
					label: "Capemisa Premiável",
					link: "https://bpc.capemisa.com.br/pages/Index.aspx?usuario=3612&token=rvtPtFwRHaPOs7C%2ffiNFPP6OkE8%3d",
				},
				{
					label: "Unimed",
					link: "https://www.corretoraunimed.com.br/ap/simulacao/2931/cotacao?areaAcao=souzapilares_corretoradeseguros",
				},
			],
		},
		{
			tittle: "Responsabilidade Civil",
			icon: <FaUserTie size={iconSize} className={styles.iconStyle} />,
			data: [
				{
					label: "Unimed",
					link: "https://www.corretoraunimed.com.br/rcp?areaAcao=souzapilares_corretoradeseguros",
				},
			],
		},
		{
			tittle: "Previdencia Privada",
			icon: <GiPayMoney size={iconSize} className={styles.iconStyle} />,
			data: [
				{
					label: "Unimed",
					link: "https://www.corretoraunimed.com.br/previdencia/simulacao/20868/cotacao?areaAcao=souzapilares_corretoradeseguros",
				},
			],
		},
		{
			tittle: "Renda",
			icon: <GiMoneyStack size={iconSize} className={styles.iconStyle} />,
			data: [
				{
					label: "Unimed",
					link: "https://www.corretoraunimed.com.br/renda?areaAcao=souzapilares_corretoradeseguros",
				},
			],
		},
	];

	return (
		<div className={styles.container}>
			{buttonsInfo.map((button) => (
				<ButtonRedirect key={button.tittle} links={button}>
					<div>{button.icon}</div>
				</ButtonRedirect>
			))}
		</div>
	);
}

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			display: "flex",
			flexWrap: "wrap",
			justifyContent: "center",
			alignItems: "start",
			backgroundColor: BLUE_DARK,
		},
		buttonRedirectContainer: {
			display: "flex",
			justifyContent: "start",
			alignItems: "start",
		},
		iconStyle: {
			color: WHITE,
		},
	})
);
