import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Typography } from '@material-ui/core';
import { BLUE_DARK, BLUE_LIGHT, REGULAR, WHITE } from '../helpers/constants';

export default function Infos() {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			<Typography className={styles.firstSquare}>Informações</Typography>
		</div>
	);
}

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			paddingTop: '1%',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'start',
			alignItems: 'center',
			backgroundColor: BLUE_DARK,
			paddingBottom: 70,
		},
		firstSquare: {
			display: 'flex',
			color: WHITE,
			width: 250,
			fontSize: 28,
			justifyContent: 'center',
			alignItems: 'center',
			fontFamily: REGULAR,
			fontWeight: 'bold',
			borderStyle: 'solid',
			borderWidth: 0,
			borderBottomWidth: 2,
			borderColor: BLUE_LIGHT,
			height: 20,
			padding: 30,
		},
	})
);
