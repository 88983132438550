import Logo from '../assets/SouzaPilares.svg';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Typography } from '@material-ui/core';
import PhoneIcon from '@material-ui/icons/Phone';
import { BLUE_DARK, BLUE_LIGHT, BLUE_TRANSPARENT, REGULAR } from '../helpers/constants';

export default function Header() {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			<div className={styles.logomarkContainer}>
				<img src={Logo} className={styles.logo} alt="logo" />
				<div className={styles.textContainer}>
					<Typography className={styles.souzaPilaresText}>Souza Pilares</Typography>
					<Typography className={styles.corretoraText}>corretora de seguros</Typography>
				</div>
			</div>
			<div className={styles.rightContainer}>
				<PhoneIcon className={styles.phoneIcon} />
				<div className={styles.callContainer}>
					<Typography className={styles.callText}>Ligue agora!</Typography>
					<Typography className={styles.phoneNumberText}>(27)3207-9283</Typography>
				</div>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: 'flex',
			flexWrap: 'wrap',
			justifyContent: 'center',
			alignItems: 'center',
			paddingTop: '1%',
			minHeight: 60,
			minWidth: 300,
		},
		logomarkContainer: {
			display: 'flex',
			paddingBottom: 8,
			justifyContent: 'center',
			paddingTop: 8,
			minWidth: 300,
			width: '30%',
		},
		textContainer: {
			paddingLeft: 10,
			fontFamily: REGULAR,
		},
		callContainer: {
			justifyContent: 'center',
			alignItems: 'center',
			paddingLeft: '1%',
		},
		rightContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'center',
			alignItems: 'center',
			minWidth: 300,
			paddingBottom: 8,
			paddingTop: 8,
			paddingRight: 55,
			width: '30%',
			[theme.breakpoints.down('sm')]: {
				backgroundColor: BLUE_TRANSPARENT,
				width: '100%',
			},
		},
		phoneIcon: {
			color: BLUE_LIGHT,
			fontSize: 55,
		},
		logo: {
			minWidth: 40,
			minHeight: 40,
		},
		souzaPilaresText: {
			display: 'flex',
			fontSize: 34,
			color: BLUE_DARK,
			lineHeight: '90%',
			fontFamily: REGULAR,
			fontWeight: 'bold',
		},
		corretoraText: {
			display: 'flex',
			fontWeight: 'bold',
			paddingLeft: 2,
			fontFamily: REGULAR,
			fontSize: 18,
			color: BLUE_LIGHT,
			lineHeight: '90%',
		},
		callText: {
			display: 'flex',
			fontWeight: 'bold',
			fontSize: 26,
			color: BLUE_LIGHT,
			lineHeight: 1.2,
			justifyContent: 'start',
			alignItems: 'center',
			fontFamily: REGULAR,
		},
		phoneNumberText: {
			display: 'flex',
			fontFamily: REGULAR,
			fontWeight: 'bold',
			fontSize: 22,
			color: BLUE_DARK,
			lineHeight: 1.2,
			justifyContent: 'center',
			alignItems: 'center',
		},
	})
);
