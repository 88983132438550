import { makeStyles } from "@material-ui/core/styles";
import { createStyles, Typography, Button } from "@material-ui/core";
import { BLUE, BLUE_DARK, BLUE_LIGHT, REGULAR, WHITE } from "../helpers/constants";
import PropTypes from "prop-types";

ButtonRedirect.propTypes = {
	links: PropTypes.any,
	children: PropTypes.children,
};

export default function ButtonRedirect(props) {
	const { links = [], children } = props;
	console.log(links);
	const styles = useStyles();
	const openInNewTab = (url) => {
		const newWindow = window.open(url, "_blank", "noopener,noreferrer");
		if (newWindow) newWindow.opener = null;
	};

	return (
		<div className={styles.container}>
			{children}
			<div className={styles.tittleContainer}>
				<Typography className={styles.seguroText}>{links.tittle}</Typography>
			</div>
			<div className={styles.paddingButton}>
				{links.data.map((button) => (
					<Button
						key={button.link}
						className={styles.linkButton}
						onClick={() => openInNewTab(`${button.link}`)}
						variant="contained"
						color="primary"
					>
						{button.label}
					</Button>
				))}
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "start",
			alignItems: "center",
			backgroundColor: BLUE_DARK,
			paddingBottom: 24,
			width: 140,
		},
		iconStyle: {
			color: WHITE,
		},
		paddingButton: {
			paddingBottom: 5,
			paddingTop: 5,
			display: "flex",
			flexDirection: "column",
			gap: "0.5rem",
		},
		tittleContainer: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
			height: 50,
		},
		seguroText: {
			color: WHITE,
			fontWeight: "bold",
			textAlign: "center",
			width: 140,
			fontFamily: REGULAR,
			[theme.breakpoints.down("sm")]: {
				fontSize: 14,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: 16,
			},
			paddingTop: "6%",
			paddingBottom: "5%",
		},
		linkButton: {
			fontSize: 12,
			fontFamily: REGULAR,
			backgroundColor: BLUE,
			color: WHITE,
			paddingTop: 7,
			paddingButton: 0,
			//height: '80%',
			height: 60,
			width: 90,
			[theme.breakpoints.down("sm")]: {
				fontSize: 11,
				height: 60,
				width: 70,
			},
			[theme.breakpoints.up("md")]: {
				fontSize: 12,
				height: 60,
				width: 90,
			},

			textTransform: "capitalize",
			"&:hover": {
				background: BLUE_LIGHT,
			},
		},
	})
);
