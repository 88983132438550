import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core";
import { WHITE } from "../helpers/constants";
import Anadem from "../assets/seguradoras/anadem.png";
import Azul from "../assets/seguradoras/azul.jpg";
import Banestes from "../assets/seguradoras/banestes.png";
import Capemisa from "../assets/seguradoras/capemisa.jpg";
import Chubb from "../assets/seguradoras/chubb.png";
import Hdi from "../assets/seguradoras/hdi.png";
import Icatu from "../assets/seguradoras/icatu.jpg";
import Liberty from "../assets/seguradoras/liberty.png";
import Mapfre from "../assets/seguradoras/mapfre.jpg";
import Metlife from "../assets/seguradoras/metlife.png";
import Mongeral from "../assets/seguradoras/mongeral.jpg";
import Omint from "../assets/seguradoras/omint.jpg";
import Porto from "../assets/seguradoras/porto.jpg";
import Suhai from "../assets/seguradoras/suhai.jpg";
import Sulamerica from "../assets/seguradoras/sulamerica.jpg";
import Tokyo from "../assets/seguradoras/tokyo.png";
import Unimed from "../assets/seguradoras/unimed.jpg";
import Azos from "../assets/seguradoras/azos.svg";

export default function Features() {
	const styles = useStyles();
	const logos = [
		{ src: Azul, name: "Azul" },
		{ src: Anadem, name: "Anadem" },
		{ src: Banestes, name: "Banestes" },
		{ src: Capemisa, name: "Capemisa" },
		{ src: Chubb, name: "Chubb" },
		{ src: Hdi, name: "Hdi" },
		{ src: Icatu, name: "Icatu" },
		{ src: Liberty, name: "Liberty" },
		{ src: Metlife, name: "Metlife" },
		{ src: Mongeral, name: "Mongeral" },
		{ src: Omint, name: "Omint" },
		{ src: Porto, name: "Porto Seguro" },
		{ src: Suhai, name: "Suhai" },
		{ src: Sulamerica, name: "Sulamerica" },
		{ src: Tokyo, name: "Tokyo Marine" },
		{ src: Unimed, name: "Unimed" },
		{ src: Mapfre, name: "Mapfre" },
		{ src: Azos, name: "Azos" },
	];

	return (
		<div className={styles.alignContainer}>
			<div className={styles.container}>
				{logos.map((item, index) => (
					<div key={index} className={styles.imageContainer}>
						<img
							className={styles.image}
							key={index}
							src={item.src}
							alt={`${item.name} Logo`}
						></img>
					</div>
				))}
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: "flex",
			flexWrap: "wrap",
			justifyContent: "center",
			alignItems: "center",
			backgroundColor: WHITE,
			width: "60%",
			[theme.breakpoints.down("sm")]: {
				width: "100%",
			},
			[theme.breakpoints.up("md")]: { width: "60%" },
		},
		imageContainer: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: 20,
			height: "100%",
		},
		image: {
			minHeight: 10,
			maxHeight: 70,
		},
		alignContainer: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
	})
);
