import Header from "./components/Header";
import { makeStyles } from "@material-ui/core/styles";
import { createStyles } from "@material-ui/core";
import RotationBar from "./components/RotationBar";
import ImageSlide from "./components/ImageSlide";
import "./App.css";
import InputCard from "./components/InputCard";
import MiddleBar from "./components/MiddleBar";
import AboutUs from "./components/AboutUs";
import Seguros from "./components/Seguros";
import ButtonIcons from "./components/ButtonIcons";
import InfoButtons from "./components/InfoButtons";
import Infos from "./components/Infos";
import Features from "./components/Features";
import GoogleMaps from "./components/GoogleMaps";
import Contact from "./components/Contact";

export default function App() {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			<Header />
			<RotationBar />
			<div className={styles.bannerContainer}>
				<div className={styles.inputContainer}>
					<InputCard />
				</div>
				<ImageSlide />
			</div>
			<MiddleBar />
			<AboutUs />
			<Seguros />
			<ButtonIcons />
			<Infos />
			<InfoButtons />
			<GoogleMaps />
			<Contact />
			<Features />
		</div>
	);
}

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			width: "100%",
		},
		bannerContainer: {
			position: "relative",
			height: 395,
		},
		inputContainer: {
			position: "absolute",
			zIndex: 3,
			right: 0,
			[theme.breakpoints.down("sm")]: {
				top: "50%",
				left: "50%",
				transform: "translate(-50%, -50%)",
				right: "auto",
			},
		},
	})
);
