import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import * as emailjs from "emailjs-com";
import * as Yup from "yup";
import { Alert } from "@material-ui/lab/";
import { createStyles, Typography, TextField } from "@material-ui/core";
import {
	BLUE_DARK,
	WHITE,
	RED,
	BLUE_LIGHT,
	BLUE_DARK_TRANSPARENT,
	REGULAR,
} from "../helpers/constants";
import { useFormik } from "formik";

const messageSchema = Yup.object().shape({
	nome: Yup.string().min(3, "O nome deve conter três letras ou mais").required("Digite seu nome"),

	email: Yup.string().email("E-Mail inválido").required("Digite o E-mail"),

	telefone: Yup.string()
		.min(10, "Incluao o DDD seguido do número.")
		.required("Digite seu Telefone")
		.matches(
			/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
			"Número de telefone inválido!"
		),

	mensagem: Yup.string()
		.min(20, "A mensagem deve contar no minimo 20 digitos")
		.max(400, "A mensagem deve ter no máximo 400 caracteres")
		.required("Digite a mensagem"),
});

export default function InputCard() {
	const styles = useStyles();
	const userId = process.env.REACT_APP_USER_ID;
	const templateId = process.env.REACT_APP_TEMPLATE_ID;
	const serviceId = process.env.REACT_APP_SERVICE_ID;
	const [toggleAlert, setToggleAlert] = React.useState(false);
	const [toggleSucces, setToggleSucces] = React.useState(false);

	const formik = useFormik({
		initialValues: {
			nome: "",
			email: "",
			telefone: "",
			mensagem: "",
		},
		validationSchema: messageSchema,
		onSubmit: () => {
			emailjs
				.send(
					serviceId,
					templateId,
					{
						mensagem: formik.values.mensagem,
						email: formik.values.email,
						nome: formik.values.nome,
						telefone: formik.values.telefone,
					},
					userId
				)
				.then(
					(result) => {
						console.log("Email Enviado", result.text);
						setToggleSucces(true);
						setTimeout(() => {
							setToggleSucces(false);
						}, 3000);
						setTimeout(() => {
							window.location.reload(false);
						}, 3000);
					},
					(error) => {
						console.log("Envio Falhou", error.text);
						setToggleAlert(true);
						setTimeout(() => {
							setToggleAlert(false);
						}, 3000);
					}
				);
		},
	});

	return (
		<div className={styles.container}>
			<Typography className={styles.textCard}>SOLICITE SUA COTAÇÃO</Typography>
			<form>
				<div className={styles.inputContainer}>
					<TextField
						name="nome"
						onChange={formik.handleChange("nome")}
						onBlur={() => formik.setFieldTouched("nome")}
						InputProps={{
							style: {
								paddingLeft: "3%",
								width: "100%",
								paddingRight: "3%",
								fontSize: 14,
								fontFamily: REGULAR,
							},
						}}
						InputLabelProps={{
							style: {
								color: BLUE_DARK,
								paddingLeft: "3%",
								fontSize: 13,
								fontFamily: REGULAR,
							},
						}}
						className={styles.textFieldContainer}
						id="standard-secondary"
						label="Nome"
					/>
				</div>

				<div className={styles.validationContainer}>
					<Typography className={styles.inputValidationErroText}>
						{formik.touched.nome && formik.errors.nome && formik.errors.nome}
					</Typography>
				</div>

				<div className={styles.inputContainer}>
					{toggleAlert && (
						<Alert
							className={styles.alert}
							severity="error"
							onClose={() => {
								setToggleAlert(false);
							}}
						>
							Cotação não enviada.
						</Alert>
					)}
					{toggleSucces && (
						<Alert
							className={styles.alert}
							severity="success"
							onClose={() => {
								setToggleSucces(false);
							}}
						>
							Cotação Enviada.
						</Alert>
					)}

					<TextField
						onChange={formik.handleChange("email")}
						onBlur={() => formik.setFieldTouched("email")}
						InputProps={{
							style: {
								paddingLeft: "3%",
								width: "100%",
								paddingRight: "3%",
								fontSize: 14,
								fontFamily: REGULAR,
							},
						}}
						InputLabelProps={{
							style: {
								color: BLUE_DARK,
								paddingLeft: "3%",
								fontSize: 13,
								fontFamily: REGULAR,
							},
						}}
						className={styles.textFieldContainer}
						id="standard-secondary"
						label="E-Mail"
					/>
				</div>
				<div className={styles.validationContainer}>
					<Typography className={styles.inputValidationErroText}>
						{formik.touched.email && formik.errors.email && formik.errors.email}
					</Typography>
				</div>
				<div className={styles.inputContainer}>
					<TextField
						onChange={formik.handleChange("telefone")}
						onBlur={() => formik.setFieldTouched("telefone")}
						autoComplete={"off"}
						InputProps={{
							style: {
								paddingLeft: "3%",
								width: "100%",
								paddingRight: "3%",
								fontSize: 14,
								fontFamily: REGULAR,
							},
						}}
						InputLabelProps={{
							style: {
								color: BLUE_DARK,
								paddingLeft: "3%",
								fontSize: 13,
								fontFamily: REGULAR,
							},
						}}
						className={styles.textFieldContainer}
						id="standard-secondary"
						label="Telefone"
						type="number"
					/>
				</div>

				<div className={styles.validationContainer}>
					<Typography className={styles.inputValidationErroText}>
						{formik.touched.telefone &&
							formik.errors.telefone &&
							formik.errors.telefone}
					</Typography>
				</div>

				<div className={styles.inputContainer}>
					<TextField
						onChange={formik.handleChange("mensagem")}
						onBlur={() => formik.setFieldTouched("mensagem")}
						multiline={true}
						rows={4}
						InputProps={{
							style: {
								paddingLeft: "3%",
								paddingRight: "3%",
								width: "100%",
								height: "83%",
								fontSize: 14,
								fontFamily: REGULAR,
							},
						}}
						InputLabelProps={{
							style: {
								color: BLUE_DARK,
								paddingLeft: "3%",
								fontSize: 13,
								fontFamily: REGULAR,
							},
						}}
						className={styles.messageFieldContainer}
						id="standard-secondary"
						label="Mensagem"
					/>
				</div>

				<div className={styles.validationContainer}>
					<Typography className={styles.inputValidationErroText}>
						{formik.touched.mensagem &&
							formik.errors.mensagem &&
							formik.errors.mensagem}
					</Typography>
				</div>
				<div className={styles.buttonContainer}>
					<Button
						className={styles.sendButton}
						onClick={formik.handleSubmit}
						variant="contained"
						color="primary"
					>
						Enviar
					</Button>
				</div>
			</form>
		</div>
	);
}

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			maxWidth: 385,
			height: 395,
			minWidth: 320,
			maxHeight: 437,
			backgroundColor: BLUE_DARK_TRANSPARENT,
			[theme.breakpoints.down("sm")]: {
				width: screen.width,
			},
			[theme.breakpoints.up("md")]: {
				width: 385,
			},
		},
		sendButton: {
			width: "80%",
			fontSize: 14,
			backgroundColor: BLUE_DARK,
			color: WHITE,
			"&:hover": {
				background: BLUE_LIGHT,
			},
		},
		validationContainer: {
			//width: '50%',
			paddingLeft: "15%",
			display: "flex",
			justifyContent: "start",
			alignItems: "center",
		},

		textFieldContainer: {
			backgroundColor: WHITE,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: "80%",
			height: 45,
			borderRadius: "5px 5px 0px 0px",
		},
		messageFieldContainer: {
			backgroundColor: WHITE,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: "80%",
			height: 90,
			borderRadius: "5px 5px 0px 0px",
		},
		inputValidationErroText: {
			fontSize: 10,
			color: RED,
			paddingTop: "1%",
			height: 18,
			fontFamily: REGULAR,
		},
		formContainer: {
			display: "grid",
			gridTemplateColumns: "1fr",
			alignItems: "center",
			justifyContent: "center",
			width: "95%",
		},
		alert: {
			position: "absolute",
			zIndex: 4,
			top: "87%",
			height: 42,
			paddingTop: 2,
			width: "78%",
		},
		textCard: {
			fontSize: 16,
			color: WHITE,
			width: "100%",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: 12,
			//paddingLeft: '3%',
			fontFamily: REGULAR,
		},
		inputContainer: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "100%",
			paddingLeft: "3%",
		},
		buttonContainer: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "100%",
			paddingBottom: "4%",
			paddingLeft: "3%",
		},
	})
);
