import { makeStyles } from "@material-ui/core/styles";
import { createStyles, Typography } from "@material-ui/core";
import { BLUE_DARK, BLUE_LIGHT, REGULAR, WHITE } from "../helpers/constants";

export default function MiddleBar() {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			<Typography className={styles.firstSquare}>
				Seguro de Vida, Previdência, Incapacidade Temporária, Responsabilidade Civil e
				outros
			</Typography>
			<div className={styles.middleSquare} />
			<Typography className={styles.secondSquare}>
				Protegendo você, sua família e seu patrimônio
			</Typography>
		</div>
	);
}

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "start",
			backgroundColor: WHITE,
		},
		firstSquare: {
			display: "flex",
			backgroundColor: BLUE_DARK,
			color: WHITE,
			minHeight: 150,
			fontSize: 24,
			lineHeight: 1.2,
			justifyContent: "start",
			alignItems: "start",
			fontFamily: REGULAR,
			fontWeight: "bold",
			padding: 10,
			[theme.breakpoints.down("sm")]: {
				width: "50%",
				fontSize: 18,
			},
			[theme.breakpoints.up("md")]: {
				width: "25%",
			},
		},
		middleSquare: {
			backgroundColor: WHITE,

			[theme.breakpoints.up("md")]: {
				width: "5%",
			},
		},
		secondSquare: {
			display: "flex",
			backgroundColor: BLUE_LIGHT,
			minHeight: 150,
			color: WHITE,
			fontSize: 24,
			lineHeight: 1.2,
			justifyContent: "start",
			alignItems: "start",
			fontFamily: REGULAR,
			fontWeight: "bold",
			padding: 10,
			[theme.breakpoints.down("sm")]: {
				width: "50%",
				fontSize: 18,
			},
			[theme.breakpoints.up("md")]: {
				width: "25%",
			},
		},
	})
);
