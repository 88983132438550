import { makeStyles } from "@material-ui/core/styles";
import { createStyles, Typography } from "@material-ui/core";
import { BLUE_DARK, BLUE_LIGHT, REGULAR, WHITE } from "../helpers/constants";

export default function Seguros() {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			<Typography className={styles.firstSquare}>Seguros</Typography>
			<Typography className={styles.secondSquare}>
				Faça a cotação do seu seguro clicando abaixo na seguradora de preferência!
			</Typography>
		</div>
	);
}

const useStyles = makeStyles(() =>
	createStyles({
		container: {
			paddingTop: "1%",
			display: "flex",
			flexDirection: "column",
			justifyContent: "start",
			alignItems: "center",
			backgroundColor: BLUE_DARK,
		},
		firstSquare: {
			display: "flex",
			color: WHITE,
			width: 250,
			fontSize: 28,
			justifyContent: "center",
			alignItems: "center",
			fontFamily: REGULAR,
			fontWeight: "bold",
			borderStyle: "solid",
			borderWidth: 0,
			borderBottomWidth: 2,
			borderColor: BLUE_LIGHT,
			height: 20,
			padding: 30,
		},
		secondSquare: {
			display: "flex",
			color: WHITE,
			minWidth: 300,
			maxWidth: 600,
			fontSize: "120%",
			lineHeight: 1.2,
			textAlign: "center",
			justifyContent: "center",
			alignItems: "start",
			fontFamily: REGULAR,
			//fontWeight: 'bold',
			padding: 30,
		},
	})
);
