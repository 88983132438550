import React from "react";
import { Fade } from "react-slideshow-image";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import {opacity} from 'react-lazy-load-image-component/src/effects/opacity.css';

import background1 from "../assets/seguros1.png";
import background2 from "../assets/seguros3.png";
console.debug(background1);
console.debug(background2);

const slideImages = ["/static/media/seguros1.28f66a25.png", "/static/media/seguros3.752e3e25.png"];

const properties = {
	duration: 3000,
	arrows: false,
};

export default function ImageSlide() {
	return (
		<Fade {...properties}>
			{slideImages.map((each, index) => (

					<LazyLoadImage
					key={index}
					effect={opacity}
					style={{
						width: "100%",
						height: 395,
						backgroundImage: `url(${each})`,
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
						backgroundPosition: "center center",
					}}/>
			))}
		</Fade>
	);
}
