import { makeStyles } from "@material-ui/core/styles";
import { createStyles, Typography } from "@material-ui/core";
import { BLUE_DARK, REGULAR, WHITE } from "../helpers/constants";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PhoneIcon from "@material-ui/icons/Phone";
import RoomIcon from "@material-ui/icons/Room";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm";
import MailIcon from "@material-ui/icons/Mail";

export default function Contact() {
	const styles = useStyles();

	return (
		<div className={styles.container}>
			<div className={styles.lineContainer}>
				<PhoneIcon className={styles.icon} />
				<Typography className={styles.contactText}>(27) 3207-9283</Typography>
			</div>
			<div className={styles.lineContainer}>
				<WhatsAppIcon className={styles.icon} />
				<Typography className={styles.contactText}>(27) 99780-9655</Typography>
			</div>
			<div className={styles.lineContainer}>
				<AccessAlarmIcon className={styles.icon} />
				<Typography className={styles.contactText}>Funcionamos de 09h às 18h</Typography>
			</div>
			<div className={styles.lineContainer}>
				<MailIcon className={styles.icon} />
				<Typography className={styles.contactText}>
					administrativo@souzapilares.com.br
				</Typography>
			</div>
			<div className={styles.lineContainer}>
				<div className={styles.mapIcon}>
					<RoomIcon className={styles.icon} />
				</div>
				<Typography className={styles.contactText}>
					Rua Gelú Vervloet dos Santos 500, Edifício Omni Office, Sala 1309, Jardim
					Camburi
				</Typography>
			</div>
		</div>
	);
}

const useStyles = makeStyles((theme) =>
	createStyles({
		container: {
			display: "flex",
			flexDirection: "column",
			justifyContent: "start",
			alignItems: "center",
			width: "100%",
			backgroundColor: WHITE,
		},
		lineContainer: {
			display: "flex",
			flexDirection: "row",
			justifyContent: "center",
			alignItems: "center",
			paddingTop: 9,
			[theme.breakpoints.down("sm")]: {
				paddingLeft: 12,
				width: "100%",
				fontSize: 18,
				justifyContent: "start",
				paddingRight: 12,
			},
			[theme.breakpoints.up("md")]: {},
		},
		contactText: {
			padding: 3,
			paddingLeft: 12,

			fontFamily: REGULAR,
			[theme.breakpoints.down("sm")]: {
				fontSize: 14,
			},
			[theme.breakpoints.up("md")]: { fontSize: 18 },
		},
		icon: {
			color: BLUE_DARK,
		},
		mapIcon: {},
	})
);
